a {
  color: inherit;
  text-decoration: none;
}

#app {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #00000017;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical {
  min-height: 10px;
  background: #0006 padding-box padding-box;
  border: 2px solid #0000;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  min-height: 10px;
  background: #0006 padding-box padding-box;
  border: 2px solid #0000;
  border-radius: 100px;
}

::-webkit-scrollbar-corner {
  background-color: #0000;
}

/*# sourceMappingURL=index.4ed4c34e.css.map */
