a {
    text-decoration: none;
    color: inherit;
}

#app {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

::-webkit-scrollbar-thumb:vertical {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
}

::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100px;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
